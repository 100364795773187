<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>
                  {{category.name}} Application discussion <span class="badge badge-square badge-primary -mt-5">{{ category.discussion_count }}</span>
                </h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard </router-link>\ {{category.name}} Application discussion
                </div>
              </div>
              <div class="breadcrumb-right">
                <div class="card-toolbar">
                  <v-btn
                      class="btn btn-primary font-weight-bolder font-size-sm"
                      v-if="checkIsAccessible('application-discussion', 'create')"
                      @click="createApplicationDiscussion()"
                  >
                    <i class="fa fa-plus"></i>
                    Add application discussion
                  </v-btn>
                  <v-btn class="btn btn-standard font-weight-bolder font-size-sm ml-5"
                         v-if="checkIsAccessible('application-discussion', 'create')"
                         @click="discussionClosed()"
                  >
                    <i class="fa fa-close"></i>
                    Discussion closed
                  </v-btn>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body">
            <!--begin::Inbox App - Messages -->
            <div class="d-flex flex-column flex-lg-row">
              <!--begin::Sidebar-->
              <div class="flex-column flex-lg-row-auto w-100 w-lg-275px mb-10 mb-lg-0">
                <!--begin::Sticky aside-->
                <div class="card card-flush mb-0">
                  <!--begin::Aside content-->
                  <div class="card-body">
                    <div class="navi navi-hover min-w-md-200px">
                      <b-dropdown-text tag="div" class="my-3 navi-item" >
                        <a @click="getAllApplicationDiscussion('all')" class="navi-link">
                                  <span class="navi-icon">
                                      <v-icon color="blue darken-2">fas fa-inbox</v-icon>
                                  </span>
                          <span class="menu-title font-size-lg fw-bold">Inbox</span>
                        </a>
                      </b-dropdown-text>

                      <b-dropdown-text tag="div" class="my-3 navi-item" >
                        <a @click="getAllApplicationDiscussion('is_read', '1')" class="navi-link">
                                  <span class="navi-icon">
                                      <v-icon color="blue darken-2">fas fa-book-reader</v-icon>
                                  </span>
                          <span class="menu-title font-size-lg fw-bold">Read</span>
                        </a>
                      </b-dropdown-text>

                      <b-dropdown-text tag="div" class="my-3 navi-item" >
                        <a @click="getAllApplicationDiscussion('is_read', '0')" class="navi-link">
                                  <span class="navi-icon">
                                      <v-icon color="blue darken-2">fas fa-book-reader</v-icon>
                                  </span>
                          <span class="menu-title font-size-lg fw-bold">Not read</span>
                        </a>
                      </b-dropdown-text>

                      <b-dropdown-text tag="div" class="my-3 navi-item" >
                        <a @click="getAllApplicationDiscussion('is_starred', '1')" class="navi-link">
                                  <span class="navi-icon">
                                      <v-icon color="blue darken-2">fas fa-star</v-icon>
                                  </span>
                          <span class="menu-title font-size-lg fw-bold">Starred</span>
                        </a>
                      </b-dropdown-text>

<!--                      <b-dropdown-text tag="div" class="my-3 navi-item" >-->
<!--                        <a @click="getAllApplicationDiscussion('is_starred', '0')" class="navi-link">-->
<!--                                  <span class="navi-icon">-->
<!--                                      <v-icon color="blue darken-2">far fa-star</v-icon>-->
<!--                                  </span>-->
<!--                          <span class="menu-title font-size-lg fw-bold">Not starred</span>-->
<!--                        </a>-->
<!--                      </b-dropdown-text>-->

                      <b-dropdown-text tag="div" class="my-3 navi-item" >
                        <a @click="getAllApplicationDiscussion('is_pinned', '1')" class="navi-link">
                                  <span class="navi-icon">
                                      <v-icon color="blue darken-2">fas fa-bookmark</v-icon>
                                  </span>
                          <span class="menu-title font-size-lg fw-bold">Pinned</span>
                        </a>
                      </b-dropdown-text>

<!--                      <b-dropdown-text tag="div" class="my-3 navi-item" >-->
<!--                        <a @click="getAllApplicationDiscussion('is_pinned', '0')" class="navi-link">-->
<!--                                  <span class="navi-icon">-->
<!--                                      <v-icon color="blue darken-2">far fa-bookmark</v-icon>-->
<!--                                  </span>-->
<!--                          <span class="menu-title font-size-lg fw-bold">Not pinned</span>-->
<!--                        </a>-->
<!--                      </b-dropdown-text>-->
                    </div>

                  </div>
                  <!--end::Aside content-->
                </div>
                <!--end::Sticky aside-->
              </div>
              <!--end::Sidebar-->
              <!--begin::Content-->
              <div class="flex-lg-row-fluid ms-lg-7 ms-xl-10" v-if="!discussionId">
                <!--begin::Card-->
                <div class="card">
                  <div class="card-header align-items-center py-5 gap-2 gap-md-5">
                    <div class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start">
                      <div class="row">
                        <v-col cols="12" sm="6" md="3">
                          <v-menu
                              v-model="menu"
                              :close-on-content-click="false"
                              :nudge-right="40"
                              transition="scale-transition"
                              offset-y
                              min-width="auto"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                  v-model="search.date"
                                  label="Date"
                                  prepend-icon=""
                                  prepend-inner-icon="mdi-calendar"
                                  v-on:keyup.enter="searchApplicationDiscussion()"
                                  @input="search.date = $event !== null ? $event : ''"
                                  readonly
                                  v-bind="attrs"
                                  v-on="on"
                                  outlined
                                  dense
                              ></v-text-field>
                            </template>
                            <v-date-picker
                                v-model="search.date"
                                no-title
                                @input="menu = false"
                            ></v-date-picker>
                          </v-menu>
                        </v-col>
                        <v-col cols="12" sm="6" md="3">
                          <v-text-field
                              label="Search by agent or candidate name"
                              v-model="search.user"
                              outlined
                              dense
                              @input="search.user = $event !== null ? $event : ''"
                              clearable
                              v-on:keyup.enter="searchApplicationDiscussion()"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="3">
                          <v-text-field
                              label="Application Reference No."
                              v-model="search.reference_id"
                              outlined
                              dense
                              @input="search.reference_id = $event !== null ? $event : ''"
                              clearable
                              v-on:keyup.enter="searchApplicationDiscussion()"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="3">
                          <v-text-field
                              label="Name"
                              v-model="search.name"
                              outlined
                              dense
                              @input="search.name = $event !== null ? $event : ''"
                              clearable
                              v-on:keyup.enter="searchApplicationDiscussion()"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="3">
                          <v-select
                              label="Category"
                              :disabled="disableCategory"
                              v-model="search.category_id"
                              :items="categories"
                              item-text="name"
                              item-value="id"
                              outlined
                              dense
                              @input="search.category_id = $event !== null ? $event : ''"
                              v-on:keyup.enter="searchApplicationDiscussion()"
                              clearable
                          ></v-select>
                        </v-col>
                        <v-col cols="12" sm="6" md="3">
                          <v-select
                              label="Discussion Read"
                              v-model="search.is_read"
                              :items="read_types"
                              item-text="name"
                              item-value="value"
                              outlined
                              dense
                              @input="search.is_read = $event !== null ? $event : ''"
                              v-on:keyup.enter="searchApplicationDiscussion()"
                              clearable
                          ></v-select>
                        </v-col>

                        <v-col cols="12" md="3">
                          <v-select
                              :items="status"
                              v-model="search.is_active"
                              label="Status"
                              item-text="name"
                              item-value="value"
                              outlined
                              clearable
                              v-on:keyup.enter="searchApplicationDiscussion()"
                              dense
                          ></v-select>
                        </v-col>

                        <v-col cols="12" md="2">
                          <v-btn
                              @click="searchApplicationDiscussion()"
                              class="mt-1 btn btn-primary"
                              style="color: #fff"
                              :loading="loading"
                          >
                            <v-icon small elevation="2" outlined>fas fa-search</v-icon>&nbsp;
                            Search
                          </v-btn>
                        </v-col>
                      </div>

                    </div>

                  </div>

                  <div class="d-flex flex-wrap gap-1 mx-2 p-3" v-if="discussions.length > 0">
                    <!--begin::Checkbox-->
                    <div class="mt-2 ml-4 form-check form-check-sm form-check-custom form-check-solid me-3">
                      <input @change="getAllMarks" v-model="checkAllBox" class="form-check-input cursor-pointer" type="checkbox" />
                    </div>
                    <!--end::Checkbox-->
                    <!--begin::Filter-->
                    <div>
                      <div class="mt-2 topbar">
                        <b-dropdown
                            size="sm"
                            variant="link"
                            toggle-class="topbar-item text-decoration-none"
                            no-caret
                            left
                            no-flip
                        >
                          <template v-slot:button-content>
                            <i class="ki ki-bold-menu"></i>
                          </template>
                          <!--begin::Navigation-->
                          <div class="navi navi-hover min-w-md-250px">
                            <b-dropdown-text tag="div" class="navi-item">
                              <a @click="markAllRead" class="navi-link">
                                 <span class="navi-text">All Read</span>
                              </a>
                            </b-dropdown-text>
                            <b-dropdown-text tag="div" class="navi-item" v-if="discussion_ids.length > 0">
                              <a class="navi-link" @click.prevent="markAsRead">
                                <span class="navi-text">Read</span>
                              </a>
                            </b-dropdown-text>
                            <b-dropdown-text tag="div" class="navi-item" v-if="discussion_ids.length > 0">
                              <a class="navi-link" @click.prevent="markAsUnRead">
                                <span class="navi-text">Not read</span>
                              </a>
                            </b-dropdown-text>
                            <b-dropdown-text tag="div" class="navi-item" v-if="discussion_ids.length > 0">
                              <a class="navi-link" @click.prevent="markAsStarred">
                                <span class="navi-text">Starred</span>
                              </a>
                            </b-dropdown-text>
                            <b-dropdown-text tag="div" class="navi-item" v-if="discussion_ids.length > 0">
                              <a class="navi-link" @click.prevent="markAsUnStarred">
                                <span class="navi-text">Not starred</span>
                              </a>
                            </b-dropdown-text>
                          </div>
                        </b-dropdown>
                      </div>
                    </div>
                    <!--end::Filter-->

<!--                    &lt;!&ndash;begin::Archive&ndash;&gt;-->
<!--                    <a v-if="discussion_ids.length > 0" href="#" class="btn btn-sm btn-icon btn-light btn-active-light-primary" data-bs-toggle="tooltip" data-bs-placement="top" title="Archive">-->
<!--                      &lt;!&ndash;begin::Svg Icon | path: icons/duotune/communication/com010.svg&ndash;&gt;-->
<!--                      <span class="svg-icon svg-icon-2">-->
<!--																<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--																	<path d="M6 8.725C6 8.125 6.4 7.725 7 7.725H14L18 11.725V12.925L22 9.725L12.6 2.225C12.2 1.925 11.7 1.925 11.4 2.225L2 9.725L6 12.925V8.725Z" fill="currentColor" />-->
<!--																	<path opacity="0.3" d="M22 9.72498V20.725C22 21.325 21.6 21.725 21 21.725H3C2.4 21.725 2 21.325 2 20.725V9.72498L11.4 17.225C11.8 17.525 12.3 17.525 12.6 17.225L22 9.72498ZM15 11.725H18L14 7.72498V10.725C14 11.325 14.4 11.725 15 11.725Z" fill="currentColor" />-->
<!--																</svg>-->
<!--															</span>-->
<!--                      &lt;!&ndash;end::Svg Icon&ndash;&gt;-->
<!--                    </a>-->
<!--                    &lt;!&ndash;end::Archive&ndash;&gt;-->
                  </div>

                  <div class="card-body mt-1">
                    <div class="table-responsive">

                      <v-skeleton-loader
                          type="table-thead"
                          v-if="loading"
                      >
                      </v-skeleton-loader>

                      <v-skeleton-loader
                          v-if="loading"
                          type="table-row-divider@25"
                      >
                      </v-skeleton-loader>
                      <!--begin::Table-->
                      <table class="table table-hover table-row-dashed fs-6 gy-5 my-0" >

                        <!--begin::Table head-->
                        <thead class="d-none">
                        <tr class="text-left">
                          <th>Checkbox</th>
                          <th>Name</th>
                          <th>Category</th>
                          <th>Application</th>
                          <th>User</th>
                          <th>Published Date</th>
                          <th>Action</th>
                        </tr>
                        </thead>
                        <!--end::Table head-->
                        <!--begin::Table body-->
                        <tbody>
                        <tr v-bind:class="{ 'bg-gray-200': !item.is_read_admin}" v-for="(item, index) in discussions" :key="index">
                          <td class="ps-9">
                            <!--begin::Checkbox-->
                            <div class="form-check form-check-sm form-check-custom form-check-solid mt-3">
                              <input @change="getMark(index, item)" v-model="checkBox[index]" class="form-check-input" type="checkbox" value="1" />
                            </div>
                            <!--end::Checkbox-->
                          </td>
                          <td class="min-w-80px">
                            <v-icon @click="makeStarred(item)" class="mx-3 mt-5" x-small :color="item.is_starred ? 'primary' : ''">fas fa-star</v-icon>
                            <v-icon @click="makePinned(item)" class="mx-3 mt-5" x-small :color="item.is_pinned ? 'primary' : ''">fas fa-bookmark</v-icon>
                          </td>
                          <!--begin::Author-->
                          <td class="w-150px w-md-175px">
                            <a @click="viewApplicationDiscussionThread(item)" class="d-flex align-items-center text-dark">
                              <!--begin::Avatar-->
                              <div class="symbol symbol-35px me-3">
                                <div class="symbol-label bg-light-danger">
                                  <span class="text-danger">{{ item.symbol_label }}</span>
                                </div>
                              </div>
                              <!--end::Avatar-->
                              <!--begin::Name-->
                              <span class="fw-semibold">{{ item.name }}</span>
                              <!--end::Name-->
                            </a>
                          </td>
                          <!--end::Author-->
                          <!--begin::Title-->
                          <td>
                            <div class="text-dark mb-1">
                              <!--begin::Heading-->
                              <span class="fw-bold">Institution: {{item.application_institution ? item.application_institution : 'N/A'}}</span>
                              <!--end::Heading-->
                            </div>
                            <!--begin::Badges-->
                            <div class="fw-bold">Academic Program: {{item.application_academic_program ? item.application_academic_program : 'N/A'}}</div>
                            <!--end::Badges-->
                          </td>
                          <!--end::Title-->

                          <td class="w-100px text-center  mt-5 fs-7 pe-9"><br>
                           <b v-if="item.user_id">User: </b> <span v-if="item.user_id" @click="goToStudent(item.user_id)" class="fw-semibold" v-bind:class="{'text-primary' : item.user_id}"> {{item.user ? item.user : 'N/A' }}</span><br v-if="item.user_id">
                           <b v-if="item.agent_id">Agent: </b> <span v-if="item.agent_id" class="fw-semibold" > {{item.agent ? item.agent : 'N/A' }}</span>
                          </td>

                          <!--begin::Date-->
                          <td class="w-100px text-end fs-7 pe-9">
                            <span class="fw-semibold">{{item.readable_created_at}} <br> {{ item.formatted_created_at ? item.formatted_created_at : '--'}}</span>
                          </td>
                          <!--end::Date-->

                          <td class="pr-0 text-center">
                            <template>
                              <b-dropdown
                                  size="sm"
                                  variant="link"
                                  toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                                  no-caret
                                  right
                                  no-flip
                              >
                                <template v-slot:button-content>
                                  <i class="ki ki-bold-more-hor"></i>
                                </template>
                                <!--begin::Navigation-->
                                <div class="navi navi-hover min-w-md-250px">
                                  <b-dropdown-text tag="div" class="navi-item" >
                                    <a @click="goToStudent(item)" class="navi-link" v-if="checkIsAccessible('application-discussion', 'show')">
                                    <span class="navi-icon">
                                        <v-icon color="blue darken-2">fas fa-eye</v-icon>
                                    </span>
                                      <span class="navi-text">View {{item.user_id ? 'Student' : 'Agent'}}</span>
                                    </a>
                                  </b-dropdown-text>
                                  <b-dropdown-text tag="div" class="navi-item" v-if="item.application_id" >
                                    <a @click="openApplicationDetail(item.application_id)" class="navi-link" v-if="checkIsAccessible('application-discussion', 'show')">
                                    <span class="navi-icon">
                                        <v-icon color="blue darken-2">fas fa-eye</v-icon>
                                    </span>
                                      <span class="navi-text">View Application</span>
                                    </a>
                                  </b-dropdown-text>
                                </div>
                              </b-dropdown>
                            </template>
                          </td>
                        </tr>
                        <tr v-if="discussions.length == 0">
                          <td class="text-center font-weight-bold px-3" colspan="7">No application discussion</td>
                        </tr>
                        </tbody>
                        <!--end::Table body-->
                      </table>
                      <!--end::Table-->
                    </div>
                  </div>
                </div>
                <!--end::Card-->
              </div>
              <!--end::Content-->

              <discussion-view v-if="discussionId" :discussion-id="discussionId" @view-discussion="viewDiscussion"></discussion-view>
            </div>
            <!--end::Inbox App - Messages -->
          </div>
        </div>
      </div>

      <create-and-update ref="create-and-update" @refresh="getAllApplicationDiscussion"></create-and-update>
    </div>

  </v-app>
</template>
<script>
import ApplicationDiscussionService from "@/services/application/discussion/ApplicationDiscussionService";
import CreateAndUpdate from "./CreateAndUpdate";
import ApplicationDiscussionCategoryService
  from "@/services/application/discussion/category/ApplicationDiscussionCategoryService";
import DiscussionView from "@/view/pages/view/application/discussion/View";

const applicationDiscussion=new ApplicationDiscussionService();
const discussionCategory=new ApplicationDiscussionCategoryService();
export default {
  components: {DiscussionView, CreateAndUpdate},
  data() {
    return {
      discussionId: null,
      category: {},
      discussions:[],
      total: null,
      perPage: null,
      mark: 0,
      page: null,
      loading: false,
      menu: false,
      disableCategory: false,
      checkBox: [false],
      checkAllBox: false,
      discussion_ids: [],
      search:{
        date: '',
        reference_id: '',
        user: '',
        name: '',
        category_id: '',
        is_read: '',
        is_starred: '',
        is_pinned: '',
        is_active: '',
      },
      status: [
        {name: 'Active', value: 1},
        {name: 'Inactive', value: 0},
      ],
      read_types: [
        {name: 'Read discussions', value: '1'},
        {name: 'Not read discussions', value: '0'}
      ],
      categories: [],
    }
  },
  computed: {
    routeTab(){
      if(this.$route.params.category_slug != 'all'){
        return {
          title: this.category.name + ' Discussion',
        }
      }
      else{
        return {
          title: `Discussion`,
        }
      }
    },
  },
  methods:{
    openApplicationDetail(application_id) {
      this.$tabs.close().then(() => {
        this.$router.push({ name: 'application-detail', params: { id: application_id } })
      })
    },
    makeStarred(discussion) {
      let data = {
        is_starred: '',
      }
      if(discussion.is_starred) {
        data.is_starred = 0;
      } else {
        data.is_starred = 1;
      }
      applicationDiscussion.update(discussion.id, data).then(() => {
        this.$snotify.success("Discussion Starred Successfully");
        this.getAllApplicationDiscussion();
      }).then(() => {});
    },
    makePinned(discussion) {
      let data = {
        is_pinned: '',
      }
      if(discussion.is_pinned) {
        data.is_pinned = 0;
      } else {
        data.is_pinned = 1;
      }
      applicationDiscussion.update(discussion.id, data).then(() => {
        this.$snotify.success("Discussion Pinned Successfully");
        this.getAllApplicationDiscussion();
      }).then(() => {});
    },

    viewDiscussion() {
      this.discussionId = null;
    },

    createApplicationDiscussion(){
      this.$refs['create-and-update'].createApplicationDiscussion();
    },

    goToStudent(item){
      if(item.user_id) {
        this.$router.push({
          name: 'student-profile',
          params: {user_id: item.user_id}
        });
      }
      if(item.agent_id) {
        this.$router.push({
          name: 'user-agent-profile',
          params: {id: item.agent_id}
        });
      }

    },

    discussionClosed() {
      this.$router.push({
        name: 'discussion-closed',
      })
    },

    getAllMarks() {
      if(this.checkAllBox) {
        this.discussions.forEach((item, index) => {
          this.checkBox[index] = true;
          this.discussion_ids.push(item.id);
        });
      }else {
        this.discussion_ids = [];
        this.checkBox = [false];
      }
    },

    getMark(index, discussionId) {
      if(this.checkBox[index]) {
        this.discussion_ids.push(discussionId.id);
      }else {
        this.discussion_ids.splice(this.discussion_ids.indexOf(discussionId.id), 1)
      }
    },

    getAllApplicationCategory() {
      discussionCategory
          .all()
          .then((response) => {
            this.categories = response.data.discussionCategories;
            if(this.$route.params.category_slug != 'all') {
              this.disableCategory = true;
              let slug = this.$route.params.category_slug;
              this.categories.forEach(category => {
                if(category.slug === slug) {
                  this.category = category;
                  this.search.category_id = category.id;
                }
              });
            }
            this.getAllApplicationDiscussion();
          })
          .catch(() => {});
    },

    getAllApplicationDiscussion(filter = null, data = null){
     if(filter != 'all' && data) {
       this.search.is_read = '';
       this.search.is_starred = '';
       this.search.is_pinned = '';
       this.search[filter] = data;
     }else {
       this.search.is_read = '';
       this.search.is_starred = '';
       this.search.is_pinned = '';
     }
      this.loading =  true;
      applicationDiscussion
          .paginate(this.search,this.page)
          .then(response => {
            this.discussions=response.data.data;
            this.page = response.data.meta.current_page;
            this.total = response.data.meta.total;
            this.perPage = response.data.meta.per_page;
            this.loading =  false;
            this.getUnReadTicket();
          })
          .catch((err) => {});
    },

    searchApplicationDiscussion(){
      this.getAllApplicationDiscussion();
    },

    viewApplicationDiscussionThread(discussion){
      if(!discussion.is_read_admin) {
        discussion.is_read_admin = true;
        applicationDiscussion.update(discussion.id, discussion).then(response =>{
          this.getAllApplicationDiscussion();
        }).catch((err) => {
          this.$snotify.error("Oops something went wrong");
        });
      }
      this.discussionId = discussion.id
      this.discussion_ids = [];
      this.checkAllBox = false;
      this.checkBox = [false];
    },

    getUnReadTicket(){
      this.$store.dispatch('GET_DISCUSSION_CATEGORIES', this.$route.params.categoryId);
    },

    markAsRead() {
      let discussion_ids = this.setData('is_read_admin', 1);
      if(this.discussion_ids.length > 0) {
        applicationDiscussion.multipleUpdate(discussion_ids).then(response =>{
          this.$snotify.success("Successfully Marked As Read");
          this.getAllApplicationDiscussion();
          this.discussion_ids = [];
          this.checkAllBox = false;
          this.checkBox = [false];
        }).catch((err) => {
          this.$snotify.error("Oops something went wrong");
        });
      }
    },

    markAsUnRead() {
      let discussion_ids = this.setData('is_read_admin', 0);
      if(this.discussion_ids.length > 0) {
        applicationDiscussion.multipleUpdate(discussion_ids).then(response =>{
          this.$snotify.success("Marked as not read");
          this.getAllApplicationDiscussion();
          this.discussion_ids = [];
          this.checkBox = [false];
          this.checkAllBox = false;
        }).catch((err) => {
          this.$snotify.error("Oops something went wrong");
        });
      }
    },

    markAsStarred() {
      let discussion_ids = this.setData('is_starred', 1);
      if(this.discussion_ids.length > 0) {
        applicationDiscussion.multipleUpdate(discussion_ids).then(response =>{
          this.$snotify.success("Successfully Marked As Starred");
          this.getAllApplicationDiscussion();
          this.discussion_ids = [];
          this.checkAllBox = false;
          this.checkBox = [false];
        }).catch((err) => {
          this.$snotify.error("Oops something went wrong");
        });
      }
    },

    markAsUnStarred() {
      let discussion_ids = this.setData('is_starred', 0);
      if(this.discussion_ids.length > 0) {
        applicationDiscussion.multipleUpdate(discussion_ids).then(response =>{
          this.$snotify.success("Marked a not starred");
          this.getAllApplicationDiscussion();
          this.discussion_ids = [];
          this.checkBox = [false];
          this.checkAllBox = false;
        }).catch((err) => {
          this.$snotify.error("Oops something went wrong");
        });
      }
    },

    markAllRead() {
        applicationDiscussion.markAllReadTicket().then(response => {
          this.getAllApplicationDiscussion();
        }).catch((err) => {
          this.$snotify.error("Oops something went wrong");
        });
    },

    setData(index, change) {
      let data = [];
      this.discussion_ids.forEach((item) => {
        data.push(item);
      })
      return {[`${index}`]: change, data: data};
    }
  },
  mounted() {
    this.getAllApplicationCategory();
  }
}
</script>